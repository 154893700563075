
// hooks/useRealtimeAnalytics.js


import { useState, useEffect, useCallback } from 'react';
import { useAnalyticsAPI } from './useAnalyticsAPI';
import { getRealtimeMinuteRanges } from '../utils/dateUtils';
import { TIME_RANGES } from '../config/const';
import { processAnalyticsResponse } from '../utils/processAnalyticResponse';
import { useAuth } from '../Auth/AuthContext';

export const useRealtimeAnalytics = (propertyId, refreshInterval = 1200000) => { // 20 minutes
    const [data, setData] = useState({});
    const { fetchData, loading, setLoading, error, setError } = useAnalyticsAPI(propertyId);
    const {
        getCurrentUserData, authenticate
    } = useAuth();

    const userData = getCurrentUserData();
    const accessToken = userData.accessToken

    const fetchTimeframeData = useCallback(async (token, timeRange) => {
        try {
            const minuteRanges = getRealtimeMinuteRanges(timeRange);
            let aggregatedMetrics = {
                activeUsers: 0,
                eventCount: 0,
                pageViews: 0
            };

            for (const minuteRange of minuteRanges) {
                const requestBody = {
                    minuteRanges: [{
                        startMinutesAgo: minuteRange.startMinutesAgo,
                        endMinutesAgo: minuteRange.endMinutesAgo
                    }],
                    dimensions: [
                        { name: 'unifiedScreenName' }
                    ],
                    metrics: [
                        { name: 'activeUsers' },
                        { name: 'eventCount' },
                        { name: 'screenPageViews' }
                    ],
                    metricAggregations: ['TOTAL']
                };

                const result = await fetchData('runRealtimeReport', token, requestBody);
                const processedMetrics = processAnalyticsResponse(result, 'realtime');

                aggregatedMetrics.activeUsers = Math.max(
                    aggregatedMetrics.activeUsers,
                    processedMetrics.activeUsers
                );
                aggregatedMetrics.eventCount += processedMetrics.eventCount;
                aggregatedMetrics.pageViews += processedMetrics.pageViews;
            }

            return aggregatedMetrics;
        } catch (err) {
            console.error(`Error fetching realtime data for ${timeRange}:`, err);
            throw err;
        }
    }, [fetchData]);

    const fetchAllTimeframes = useCallback(async () => {
        if (!accessToken) {
            try {
                await authenticate();
                return;
            } catch (err) {
                setError('Authentication failed: ' + err.message);
                return;
            }
        }

        setLoading(true);
        const results = {};

        try {
            for (const timeRange of TIME_RANGES.REALTIME) {
                console.log('Fetching realtime data for:', timeRange);
                results[timeRange] = await fetchTimeframeData(accessToken, timeRange);
            }

            setData(results);
            setError(null);
        } catch (err) {
            console.error('Error fetching realtime data:', err);
            setError(err.message);
            if (err.message.includes('401') || err.message.includes('403')) {
                await authenticate().catch(console.error);
            }
        } finally {
            setLoading(false);
        }
    }, [accessToken, authenticate, fetchTimeframeData, setError, setLoading]);

    useEffect(() => {
        if (propertyId && !accessToken) {
            authenticate();
        }
    }, [propertyId, accessToken, authenticate]);

    useEffect(() => {
        if (accessToken) {
            fetchAllTimeframes();
            const intervalId = setInterval(fetchAllTimeframes, refreshInterval);
            return () => clearInterval(intervalId);
        }
    }, [accessToken, fetchAllTimeframes, refreshInterval]);

    return {
        data,
        loading,
        error,
        refresh: fetchAllTimeframes
    };
};