import * as React from "react"

// Tooltip Context for managing global tooltip state
const TooltipContext = React.createContext({
    open: false,
    setOpen: () => { }
})

// TooltipProvider Component
const TooltipProvider = ({ children }) => {
    const [open, setOpen] = React.useState(false)

    return (
        <TooltipContext.Provider value={{ open, setOpen }}>
            {children}
        </TooltipContext.Provider>
    )
}

// TooltipTrigger Component
const TooltipTrigger = React.forwardRef(({ children, asChild, ...props }, ref) => {
    const { setOpen } = React.useContext(TooltipContext)

    if (asChild && React.isValidElement(children)) {
        return React.cloneElement(children, {
            ref,
            onMouseEnter: () => setOpen(true),
            onMouseLeave: () => setOpen(false),
            ...props
        })
    }

    return (
        <div
            ref={ref}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            {...props}
        >
            {children}
        </div>
    )
})
TooltipTrigger.displayName = "TooltipTrigger"

// TooltipContent Component
const TooltipContent = React.forwardRef(({
    children,
    className = "",
    position = "top",
    ...props
}, ref) => {
    const { open } = React.useContext(TooltipContext)

    const positionStyles = {
        top: "bottom-full left-1/2 -translate-x-1/2 mb-2",
        bottom: "top-full left-1/2 -translate-x-1/2 mt-2",
        left: "right-full top-1/2 -translate-y-1/2 mr-2",
        right: "left-full top-1/2 -translate-y-1/2 ml-2"
    }

    if (!open) return null

    return (
        <div
            ref={ref}
            role="tooltip"
            className={`
                absolute z-50 
                ${positionStyles[position]}
                bg-black text-white 
                text-xs 
                px-3 py-1 
                rounded-md 
                shadow-lg 
                animate-fade-in
                ${className}
            `}
            {...props}
        >
            {children}
            <div
                className={`
                    absolute 
                    w-2 h-2 
                    bg-black 
                    transform 
                    rotate-45 
                    ${position === 'top' ? '-bottom-1 left-1/2 -translate-x-1/2' : ''}
                    ${position === 'bottom' ? '-top-1 left-1/2 -translate-x-1/2' : ''}
                    ${position === 'left' ? '-right-1 top-1/2 -translate-y-1/2' : ''}
                    ${position === 'right' ? '-left-1 top-1/2 -translate-y-1/2' : ''}
                `}
            />
        </div>
    )
})
TooltipContent.displayName = "TooltipContent"

// Tooltip Component (Composition)
const Tooltip = ({ children, ...props }) => {
    return (
        <TooltipProvider>
            <div className="relative inline-block">
                {React.Children.map(children, child =>
                    React.cloneElement(child, props)
                )}
            </div>
        </TooltipProvider>
    )
}
Tooltip.displayName = "Tooltip"

// Add some global styles for animation
const tooltipStyles = `
    @keyframes fade-in {
        from { opacity: 0; transform: translateY(-10px); }
        to { opacity: 1; transform: translateY(0); }
    }
    .animate-fade-in {
        animation: fade-in 0.2s ease-out;
    }
`

// Inject styles
const StyleInjector = () => (
    <style>{tooltipStyles}</style>
)

export {
    Tooltip,
    TooltipProvider,
    TooltipTrigger,
    TooltipContent,
    StyleInjector
}