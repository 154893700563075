// import React from 'react';
// import { RefreshCw, ArrowLeft, TrendingUp } from 'lucide-react';
// import QuickStatsHistorical from '../ui/QuickstatsHistorical';
// import { useNavigate } from 'react-router-dom';
// import { Button } from '../ui/button';
// import DataTable from '../ui/DataTable';
// import { useHistoricalAnalytics } from '../hooks/historicalDataHook';
// import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
// import { AnalyticsNavigation } from './AnalyticsNavigation';

// const PageHeader = ({ onBack, onRefresh, isLoading }) => (
//     <Card className="bg-gradient-to-r from-slate-900 to-blue-900 border-none shadow-2xl transform hover:scale-[1.01] transition-all duration-300 p-6">
//         <CardContent className="flex flex-col md:flex-row justify-between items-center p-6">
//             <div className="space-y-3 text-center md:text-left mb-4 md:mb-0">
//                 <div className="flex items-center gap-4">
//                     <Button
//                         onClick={onBack}
//                         variant="ghost"
//                         className="p-2 text-white/80 hover:text-white hover:bg-white/10 transition-colors"
//                     >
//                         <ArrowLeft className="h-5 w-5" />
//                     </Button>
//                     <div className="flex items-center gap-3">
//                         <TrendingUp className="h-8 w-8 text-blue-400" />
//                         <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-200 via-blue-100 to-blue-300 animate-gradient">
//                             Historical Analytics
//                         </h1>
//                     </div>
//                 </div>
//                 <p className="text-blue-200/80">
//                     Comprehensive historical data analysis spanning 6 years
//                 </p>
//                 <div className="max-w-7xl mx-auto">
//                     <AnalyticsNavigation />
//                 </div>
//             </div>
//             <Button
//                 onClick={onRefresh}
//                 className="bg-blue-500 hover:bg-blue-600 text-white flex items-center gap-2 shadow-lg hover:shadow-blue-500/30 transition-all duration-300"
//                 disabled={isLoading}
//             >
//                 <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
//                 Refresh Data
//             </Button>
//         </CardContent>
//     </Card>
// );

// const DataSection = ({ data, isLoading, error, onRetry }) => (
//     <Card className="backdrop-blur-sm bg-white/90 shadow-xl hover:shadow-2xl transition-all duration-300 border border-slate-200">
//         <CardHeader className="border-b border-slate-100">
//             <CardTitle className="text-xl font-semibold bg-gradient-to-r from-slate-900 to-blue-900 bg-clip-text text-transparent">
//                 Historical Data
//             </CardTitle>
//         </CardHeader>
//         <CardContent className="p-6">
//             <DataTable
//                 data={data}
//                 loading={isLoading}
//                 error={error}
//                 onRetry={onRetry}
//                 variant="historical"
//             />
//         </CardContent>
//     </Card>
// );

// const HistoricalAnalyticsPage = () => {
//     const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID
//     const navigate = useNavigate();
//     const {
//         data: historicalData,
//         loading: historicalLoading,
//         error: historicalError,
//         refresh: refreshHistorical
//     } = useHistoricalAnalytics(PROPERTY_ID);

//     const handleBack = () => navigate('/');

//     return (
//         <div className="min-h-screen bg-gradient-to-b from-slate-50 via-blue-50 to-slate-100 p-4 md:p-8 animate-gradient-slow">
//             <style jsx global>{`
//         @keyframes gradient {
//           0% { background-position: 0% 50%; }
//           50% { background-position: 100% 50%; }
//           100% { background-position: 0% 50%; }
//         }
//         .animate-gradient {
//           background-size: 200% 200%;
//           animation: gradient 6s ease infinite;
//         }
//         .animate-gradient-slow {
//           background-size: 200% 200%;
//           animation: gradient 15s ease infinite;
//         }
//         @keyframes fadeIn {
//           from { opacity: 0; transform: translateY(10px); }
//           to { opacity: 1; transform: translateY(0); }
//         }
//         .fade-in {
//           animation: fadeIn 0.5s ease-out forwards;
//         }
//       `}</style>
//             <div className="max-w-7xl mx-auto space-y-8">
//                 <div className="opacity-0 fade-in" style={{ animationDelay: '0.1s' }}>
//                     <PageHeader
//                         onBack={handleBack}
//                         onRefresh={refreshHistorical}
//                         isLoading={historicalLoading}
//                     />
//                 </div>

//                 <div className="opacity-0 fade-in" style={{ animationDelay: '0.3s' }}>
//                     <Card className="bg-gradient-to-br from-white via-blue-50 to-white border border-blue-100 shadow-xl hover:shadow-2xl p-7 transition-all duration-300">
//                         <CardContent className="p-6">
//                             <QuickStatsHistorical realtimeData={historicalData} />
//                         </CardContent>
//                     </Card>
//                 </div>

//                 <div className="opacity-0 fade-in" style={{ animationDelay: '0.5s' }}>
//                     <DataSection
//                         data={historicalData}
//                         isLoading={historicalLoading}
//                         error={historicalError}
//                         onRetry={refreshHistorical}
//                     />
//                 </div>
//                 {/* Footer */}
//                 <div className="opacity-0 fade-in" style={{ animationDelay: '0.6s' }}>
//                     <Card className="bg-gradient-to-r from-slate-900 to-blue-900 border-none shadow-lg p-4">
//                         <CardContent className="flex ">
//                             {/* Logo Container */}
//                             <div className="absolute top-4 right-6">
//                                 <div className="relative group">
//                                     <div className="absolute -inset-2 bg-white/20 rounded-full blur transition duration-700 group-hover:bg-indigo-400/40 group-hover:duration-200"></div>
//                                     <div className="relative">
//                                         <img
//                                             src="/logo.png"
//                                             alt="ABBA Logo"
//                                             className="h-24 w-24 object-contain transform transition-all duration-500 group-hover:scale-110 "
//                                             onError={(e) => {
//                                                 e.target.onerror = null;
//                                                 e.target.src = `data:image/svg+xml,${encodeURIComponent(`
//                           <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <rect width="64" height="64" rx="32" fill="white"/>
//                             <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="20" font-weight="bold" fill="#4F46E5">
//                               ABBA
//                             </text>
//                           </svg>
//                         `)}`;
//                                             }}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                             <p className="text-blue-200/80 items-end justify-end p-6">
//                                 Copyright © {new Date().getFullYear()} Abba Info Services, Angamaly, Kerala, India
//                             </p>
//                         </CardContent>
//                     </Card>
//                 </div>
//             </div>

//         </div>
//     );
// };

// export default HistoricalAnalyticsPage;
import React from "react";
import { RefreshCw, ArrowLeft, TrendingUp, ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

// import { motion, AnimatePresence } from 'framer-motion';
import { motion } from "../Animation/framermotion";

// UI Components
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import DataTable from "../ui/DataTable";

// Custom Hooks and Components
import { useHistoricalAnalytics } from "../hooks/historicalDataHook";
import QuickStatsHistorical from "../ui/QuickstatsHistorical";
import { AnalyticsNavigation } from "./AnalyticsNavigation";

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      staggerChildren: 0.3,
    },
  },
  exit: { opacity: 0, y: -20 },
};

const sectionVariants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 },
  },
};

const HistoricalAnalyticsPage = () => {
  const navigate = useNavigate();
  const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID;

  const {
    data: historicalData,
    loading: historicalLoading,
    error: historicalError,
    refresh: refreshHistorical,
  } = useHistoricalAnalytics(PROPERTY_ID);

  const handleBack = () => navigate("/");

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-br from-slate-50 via-blue-50 to-slate-100 py-8 px-4 md:px-8"
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="max-w-6xl mx-auto space-y-8">
        {/* Page Header */}
        <motion.div variants={sectionVariants}>
          <Card className="bg-gradient-to-r from-gray-900 to-red-900 border-none shadow-2xl overflow-hidden">
            <div className="p-6 md:p-8 flex flex-col md:flex-row justify-between items-center">
              <div className="space-y-4 text-center md:text-left mb-4 md:mb-0">
                <div className="flex items-center gap-4">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          onClick={handleBack}
                          variant="ghost"
                          className="p-2 text-white/80 hover:text-white hover:bg-white/10 transition-colors"
                        >
                          <ArrowLeft className="h-5 w-5" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>Back to Dashboard</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>

                  <div className="flex items-center gap-3">
                    <TrendingUp className="h-8 w-8 text-blue-400" />
                    <h1 className="text-3xl md:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-200 via-blue-100 to-blue-300">
                      Historical Analytics
                    </h1>
                  </div>
                </div>

                <p className="text-blue-200/80 max-w-md">
                  Comprehensive data analysis metrics over the past 6 years.
                </p>

                <AnalyticsNavigation />
              </div>

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      onClick={refreshHistorical}
                      className="bg-blue-500 hover:bg-blue-600 text-white flex items-center gap-2 shadow-lg hover:shadow-blue-500/30 transition-all"
                      disabled={historicalLoading}
                    >
                      <RefreshCw
                        className={`h-4 w-4 ${
                          historicalLoading ? "animate-spin" : ""
                        }`}
                      />
                      Refresh Data
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {historicalLoading ? "Updating..." : "Fetch Latest Data"}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </Card>
        </motion.div>

        {/* Quick Stats */}
        <motion.div variants={sectionVariants}>
          <Card className="bg-white/90 backdrop-blur-sm border border-blue-100 shadow-xl hover:shadow-2xl transition-all">
            <div className="p-6">
              <QuickStatsHistorical
                realtimeData={historicalData}
                loading={historicalLoading}
              />
            </div>
          </Card>
        </motion.div>

        {/* Historical Data Table */}
        <motion.div variants={sectionVariants}>
          <Card className="bg-white/90 backdrop-blur-sm border border-blue-100 shadow-xl hover:shadow-2xl transition-all">
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-2xl font-semibold text-slate-800">
                  Detailed Historical Data
                </h2>
                <Button
                  variant="outline"
                  size="sm"
                  className="text-blue-600 hover:bg-blue-50 flex items-center gap-2"
                >
                  Export <ChevronRight className="h-4 w-4" />
                </Button>
              </div>
              <DataTable
                data={historicalData}
                loading={historicalLoading}
                error={historicalError}
                onRetry={refreshHistorical}
                variant="historical"
              />
            </div>
          </Card>
        </motion.div>

        {/* Footer */}
        <motion.div variants={sectionVariants}>
          <Card className="bg-gradient-to-r from-gray-900 to-red-900 border-none shadow-lg  p-6 ">
            <CardContent className="flex relative">
              {/* Logo Container */}
              <div className="absolute top-2 right-6">
                <div className="relative group">
                  <div className="  absolute -inset-2    bg-white/20 rounded-full    blur transition duration-700   group-hover:bg-indigo-400/40  group-hover:duration-200 "></div>
                  <div className="relative">
                    <img
                      src="/logo.png"
                      alt="ABBA Logo"
                      className=" h-24 w-24 object-contain transform transition-all duration-500 group-hover:scale-110"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `data:image/svg+xml,${encodeURIComponent(`
                          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" rx="32" fill="white"/>
                            <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="20" font-weight="bold" fill="#4F46E5">
                              ABBA
                            </text>
                          </svg>
                        `)}`;
                      }}
                    />
                  </div>
                </div>
              </div>

              <p className=" text-blue-200/80 items-end justify-end p-6 ">
                Copyright © {new Date().getFullYear()} Abba Info Services,
                Angamaly, Kerala, India
              </p>
            </CardContent>
          </Card>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default HistoricalAnalyticsPage;
