// constants.js
const TIME_RANGES = {
    HISTORICAL: [
        '1 days',
        '7 days',
        '30 days',
        '90 days',
        '180 days',
        '1 year',
        '3 years',
        '6 years'
    ],
    REALTIME: [
        '1 minutes',
        '2 minutes',
        '5 minutes',
        '10 minutes',
        '15 minutes',
        '29 minutes',
    ],
    DAU_MAU: [
        '30',
        '7',
        '1'

    ]
};

const BASE_URL = 'https://analyticsdata.googleapis.com/v1beta';
const GOOGLE_SCOPE = 'https://www.googleapis.com/auth/analytics.readonly';

// Create an object for default export
const constants = {
    TIME_RANGES,
    BASE_URL,
    GOOGLE_SCOPE
};

// Named exports
export { TIME_RANGES, BASE_URL, GOOGLE_SCOPE };

// Default export
export default constants;