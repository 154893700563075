


import React, { useState } from 'react';
import { LogOut, LineChart, Plus, Minus, Activity, History, Users, FileChartLine, Zap } from 'lucide-react';
import { useAuth } from '../Auth/AuthContext';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { useNavigate } from 'react-router-dom';

const NavigationCard = ({ title, description, icon: Icon, onClick, gradient }) => (
    <Card
        onClick={onClick}
        className="group hover:shadow-2xl transition-all duration-500 hover:-translate-y-1 cursor-pointer bg-white/80 backdrop-blur-sm p-5 relative overflow-hidden"
    >
        <div className={`absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ${gradient}`} />
        <CardContent className="p-8 flex flex-col items-center space-y-6 relative z-10">
            <div className="w-20 h-20 rounded-2xl flex items-center justify-center transform group-hover:scale-110 transition-transform duration-500"
                style={{
                    background: "linear-gradient(135deg, rgba(96, 165, 250, 0.1) 0%, rgba(99, 102, 241, 0.1) 100%)"
                }}>
                <Icon className="w-10 h-10 text-blue-600 group-hover:text-indigo-600 transition-colors duration-500 " />
            </div>
            <div className="space-y-3 text-center">
                <h2 className="text-xl font-bold text-slate-800 group-hover:text-indigo-600 transition-colors duration-300">
                    {title}
                </h2>
                <p className="text-slate-500 text-sm">
                    {description}
                </p>
            </div>
            <Button
                variant="outline"
                className="w-full group-hover:bg-indigo-600 group-hover:text-white border-indigo-200 text-indigo-600 transition-all duration-300"
            >
                View Analytics
            </Button>
        </CardContent>
    </Card>
);
// Reusable Card Component with Enhanced Interactivity
const AnalyticsCard = ({
    title,
    description,
    icon: Icon,
    onClick,
    bgGradient,
    hoverEffect
}) => (
    <div
        onClick={onClick}
        className={`
      group relative overflow-hidden rounded-2xl 
      border border-gray-100 shadow-lg 
      hover:shadow-2xl transition-all duration-500 
      cursor-pointer p-6 
      ${bgGradient}
      transform hover:-translate-y-2
    `}
    >
        <div className="absolute inset-0 bg-white/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

        <div className="relative z-10 flex flex-col space-y-4">
            <div className="flex items-center justify-between">
                <div className={`
          w-16 h-16 rounded-xl flex items-center justify-center 
          bg-white/10 backdrop-blur-sm
          ${hoverEffect}
        `}>
                    <Icon className="w-8 h-8 text-white/80" />
                </div>
                <div className="opacity-50 group-hover:opacity-100 transition-opacity">
                    <Zap className="w-5 h-5 text-white" />
                </div>
            </div>

            <div className="space-y-2">
                <h3 className="text-xl font-bold text-white">{title}</h3>
                <p className="text-white/70 text-sm">{description}</p>
            </div>
        </div>
    </div>
);

const HomePage = () => {
    const { currentUser,
        getCurrentUserData,
        logout, authenticate } = useAuth();
    const [isUserDetailsExpanded, setIsUserDetailsExpanded] = useState(false);
    const navigate = useNavigate();

    const userData = getCurrentUserData();
    const handleLogout = async () => {
        try {
            await logout(currentUser.userId);
        } catch (error) {
            console.error('Failed to logout:', error);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-slate-50 via-blue-50 to-slate-100 p-6 animate-gradient-slow">
            <style jsx global>{`
                @keyframes gradient {
                    0% { background-position: 0% 50%; }
                    50% { background-position: 100% 50%; }
                    100% { background-position: 0% 50%; }
                }
                .animate-gradient {
                    background-size: 200% 200%;
                    animation: gradient 6s ease infinite;
                }
                .animate-gradient-slow {
                    background-size: 200% 200%;
                    animation: gradient 15s ease infinite;
                }
                @keyframes fadeIn {
                    from { opacity: 0; transform: translateY(10px); }
                    to { opacity: 1; transform: translateY(0); }
                }
                .fade-in {
                    animation: fadeIn 0.5s ease-out forwards;
                }
            `}</style>
            <div className="max-w-7xl mx-auto space-y-8">
                {/* Header */}
                <div className="opacity-0 fade-in" style={{ animationDelay: '0.1s' }}>
                    <Card className="bg-gradient-to-r from-blue-900 to-indigo-900 border-none shadow-2xl p-4">
                        <CardContent className="flex flex-col md:flex-row justify-between items-center p-8">
                            <div className="space-y-3 text-center md:text-left mb-4 md:mb-0">
                                <div className='flex items-center gap-3'>
                                    <FileChartLine className="h-8 w-8 text-indigo-400 animate-pulse" />
                                    <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-200 via-blue-100 to-indigo-200 animate-gradient">
                                        Analytics Dashboard
                                    </h1>


                                </div>

                                <p className="text-blue-200/80">Welcome back, {currentUser?.displayName || 'User'}</p>
                            </div>

                            <Button
                                onClick={handleLogout}
                                className="bg-red-500 hover:bg-red-600  animate-bounce-slow text-white flex items-center gap-2  shadow-lg hover:shadow-red-500/30 transition-all duration-300"
                            >
                                <LogOut className="h-4 w-4 mr-4" />
                                Sign Out
                            </Button>
                        </CardContent>
                    </Card>

                </div>

                {/* User Details */}
                {/* <div className="opacity-0 fade-in" style={{ animationDelay: '0.2s' }}>
                    <Card className="hover:shadow-xl transition-all duration-300 backdrop-blur-sm bg-white/80">
                        <CardHeader className="flex flex-row items-center justify-between p-6">
                            <div className="flex items-center gap-3">
                                 <div className="p-2 bg-indigo-100 rounded-lg ">
                                    <User className="h-5 w-5 text-indigo-600 " />
                                </div>
                                <CardTitle className="text-lg text-slate-800">User Details</CardTitle> 
                            </div>
                            { <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setIsUserDetailsExpanded(!isUserDetailsExpanded)}
                                className="hover:bg-indigo-50 rounded-lg transition-colors"
                            >
                                {isUserDetailsExpanded ? (
                                    <Minus className="h-5 w-5 text-indigo-600" />
                                ) : (
                                    <Plus className="h-5 w-5 text-indigo-600" />
                                )}
                            </Button> }
                        </CardHeader>
                        {isUserDetailsExpanded && (
                            <CardContent className="animate-[fadeIn_0.2s_ease-in] p-6">
                                <pre className="bg-slate-50 p-6 rounded-xl overflow-auto border border-indigo-100 text-sm text-slate-700">
                                    {JSON.stringify(userData, null, 2)}
                                </pre>
                            </CardContent>
                        )}
                    </Card>
                </div> */}

                {/* Analytics Navigation Cards */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 ">
                    <div className="opacity-0 fade-in " style={{ animationDelay: '0.3s' }}>
                        <AnalyticsCard
                            title="DAU-MAU Analytics"
                            description="Daily and Monthly Active Users"
                            icon={Users}
                            onClick={() => navigate('/dau-mau-analytics')}
                            bgGradient="bg-gradient-to-tr from-yellow-600/60 to-blue-600/60"
                        />
                    </div>
                    <div className="opacity-0 fade-in" style={{ animationDelay: '0.4s' }}>
                        <AnalyticsCard
                            title="Real-time Analysis"
                            description="Live data insights & trends"
                            icon={Activity}
                            onClick={() => navigate('/realtime-analytics')}
                            bgGradient="bg-gradient-to-tr from-emerald-600/60 to-blue-600/60"
                            hoverEffect="group-hover:scale-110"
                        />


                    </div>
                    <div className="opacity-0 fade-in" style={{ animationDelay: '0.5s' }}>
                        <AnalyticsCard
                            title="Historical Data"
                            description="6-year comprehensive analytics"
                            icon={LineChart}
                            onClick={() => navigate('/historical-analytics')}
                            bgGradient="bg-gradient-to-tr from-gray-600/60 to-red-600/60"
                            hoverEffect="group-hover:-rotate-6"
                        />
                    </div>
                </div>

                {/* Footer */}
                <div className="opacity-0 fade-in" style={{ animationDelay: '0.6s' }}>
                    <Card className="bg-gradient-to-r from-slate-900 to-blue-900 border-none shadow-lg p-4">
                        <CardContent className="flex ">
                            {/* Logo Container */}
                            <div className="absolute top-4 right-6">
                                <div className="relative group">
                                    <div className="absolute -inset-2 bg-white/20 rounded-full blur transition duration-700 group-hover:bg-indigo-400/40 group-hover:duration-200"></div>
                                    <div className="relative">
                                        <img
                                            src="/logo.png"
                                            alt="ABBA Logo"
                                            className="h-24 w-24 object-contain transform transition-all duration-500 group-hover:scale-110 "
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `data:image/svg+xml,${encodeURIComponent(`
                          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" rx="32" fill="white"/>
                            <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="20" font-weight="bold" fill="#4F46E5">
                              ABBA
                            </text>
                          </svg>
                        `)}`;
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <p className="text-blue-200/80 items-end justify-end p-6">
                                Copyright © {new Date().getFullYear()} Abba Info Services, Angamaly, Kerala, India
                            </p>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default HomePage;

// import React, { useState } from 'react';
// import {
//     LineChart,
//     PieChart,
//     LogOut,
//     User,
//     Activity,
//     Layers,
//     Zap,
//     BarChart2
// } from 'lucide-react';
// import { useAuth } from '../Auth/AuthContext';
// import { Card } from '../ui/card';
// import { Button } from '../ui/button';
// import { useNavigate } from 'react-router-dom';

// // Reusable Card Component with Enhanced Interactivity
// const AnalyticsCard = ({
//     title,
//     description,
//     icon: Icon,
//     onClick,
//     bgGradient,
//     hoverEffect
// }) => (
//     <div
//         onClick={onClick}
//         className={`
//       group relative overflow-hidden rounded-2xl 
//       border border-gray-100 shadow-lg 
//       hover:shadow-2xl transition-all duration-500 
//       cursor-pointer p-6 
//       ${bgGradient}
//       transform hover:-translate-y-2
//     `}
//     >
//         <div className="absolute inset-0 bg-white/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

//         <div className="relative z-10 flex flex-col space-y-4">
//             <div className="flex items-center justify-between">
//                 <div className={`
//           w-16 h-16 rounded-xl flex items-center justify-center 
//           bg-white/10 backdrop-blur-sm
//           ${hoverEffect}
//         `}>
//                     <Icon className="w-8 h-8 text-white/80" />
//                 </div>
//                 <div className="opacity-50 group-hover:opacity-100 transition-opacity">
//                     <Zap className="w-5 h-5 text-white" />
//                 </div>
//             </div>

//             <div className="space-y-2">
//                 <h3 className="text-xl font-bold text-white">{title}</h3>
//                 <p className="text-white/70 text-sm">{description}</p>
//             </div>
//         </div>
//     </div>
// );

// const HomePage = () => {
//     const { currentUser, logout } = useAuth();
//     const navigate = useNavigate();

//     const handleLogout = async () => {
//         try {
//             await logout(currentUser.userId);
//         } catch (error) {
//             console.error('Logout failed:', error);
//         }
//     };

//     return (
//         <div className="min-h-screen bg-gradient-to-br from-gray-900 via-slate-900 to-black text-white px-6 py-12">
//             <div className="max-w-6xl mx-auto space-y-12">
//                 {/* Header Section */}
//                 <header className="flex justify-between items-center">
//                     <div>
//                         <h1 className="text-4xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
//                             Analytics Dashboard
//                         </h1>
//                         <p className="text-white/70 mt-2">
//                             Welcome back, {currentUser?.displayName || 'User'}
//                         </p>
//                     </div>

//                     <Button
//                         onClick={handleLogout}
//                         className="bg-red-600/20 text-red-300 hover:bg-red-600/40 border border-red-600/30"
//                     >
//                         <LogOut className="mr-2 w-4 h-4" /> Logout
//                     </Button>
//                 </header>

//                 {/* Analytics Navigation Grid */}
//                 <div className="grid md:grid-cols-3 gap-8">
//                     <AnalyticsCard
//                         title="User Metrics"
//                         description="Daily & Monthly Active Users"
//                         icon={User}
//                         onClick={() => navigate('/user-metrics')}
//                         bgGradient="bg-gradient-to-tr from-blue-600/60 to-purple-600/60"
//                         hoverEffect="group-hover:rotate-6"
//                     />

//                     <AnalyticsCard
//                         title="Real-time Analysis"
//                         description="Live data insights & trends"
//                         icon={Activity}
//                         onClick={() => navigate('/realtime-analytics')}
//                         bgGradient="bg-gradient-to-tr from-green-600/60 to-blue-600/60"
//                         hoverEffect="group-hover:scale-110"
//                     />

//                     <AnalyticsCard
//                         title="Historical Data"
//                         description="6-year comprehensive analytics"
//                         icon={LineChart}
//                         onClick={() => navigate('/historical-analytics')}
//                         bgGradient="bg-gradient-to-tr from-indigo-600/60 to-pink-600/60"
//                         hoverEffect="group-hover:-rotate-6"
//                     />
//                 </div>

//                 {/* Quick Stats Section */}
//                 <section className="bg-white/5 rounded-2xl p-8 border border-white/10">
//                     <div className="grid md:grid-cols-4 gap-6">
//                         {[
//                             { icon: Layers, label: 'Total Users', value: '42,582' },
//                             { icon: BarChart2, label: 'Active Today', value: '3,241' },
//                             { icon: Activity, label: 'Conversion Rate', value: '12.4%' },
//                             { icon: PieChart, label: 'Retention', value: '68%' }
//                         ].map(({ icon: Icon, label, value }) => (
//                             <div key={label} className="flex items-center space-x-4 bg-white/10 p-4 rounded-xl">
//                                 <Icon className="w-8 h-8 text-blue-400" />
//                                 <div>
//                                     <p className="text-white/60 text-xs uppercase">{label}</p>
//                                     <p className="text-xl font-bold">{value}</p>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </section>

//                 {/* Footer */}
//                 <footer className="text-center text-white/50 text-sm">
//                     © {new Date().getFullYear()} Analytics Platform. All Rights Reserved.
//                 </footer>
//             </div>
//         </div>
//     );
// };

// export default HomePage;