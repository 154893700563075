// utils/dateUtils.js
export const formatDate = (date) => date.toISOString().split('T')[0];

export const getHistoricalDateRange = (timeRange) => {
    const now = new Date();
    const start = new Date(now);

    if (timeRange.includes('hour')) {
        const hours = parseInt(timeRange);
        start.setHours(start.getHours() - hours);
        return {
            startDate: formatDate(start),
            endDate: 'today',
            dimensionFilter: hours <= 24 ? {
                filter: {
                    fieldName: 'dateHour',
                    stringFilter: {
                        matchType: 'CONTAINS_SUBSTRING',
                        value: start.getHours().toString().padStart(2, '0')
                    }
                }
            } : undefined
        };
    }

    const rangeMap = {
        '1 days': { startDate: '1daysAgo', endDate: 'today' },
        '7 days': { startDate: '7daysAgo', endDate: 'today' },
        '30 days': { startDate: '30daysAgo', endDate: 'today' },
        '90 days': { startDate: '90daysAgo', endDate: 'today' },
        '180 days': { startDate: '180daysAgo', endDate: 'today' },
        '1 year': { startDate: '365daysAgo', endDate: 'today' },
        '3 years': { startDate: '1095daysAgo', endDate: 'today' }, // 365 * 3
        '6 years': { startDate: '2190daysAgo', endDate: 'today' }  // 365 * 6
    };

    return rangeMap[timeRange] || { startDate: 'yesterday', endDate: 'today' };
};

export const REALTIME_RANGES = [
    '1 minute',
    '3 minutes',
    '5 minutes',
    '10 minutes',
    '15 minutes',
    '29 minutes'
];

export const getRealtimeMinuteRanges = (timeRange) => {
    const [value, unit] = timeRange.split(' ');
    const totalMinutes = parseInt(value);

    return [{
        name: `0_to_${totalMinutes}_minutes`,
        startMinutesAgo: totalMinutes,
        endMinutesAgo: 0
    }];
};

export const getDauMauTimeRanges = (timeRange) => {
    const DAY_IN_MINUTES = 24 * 60;
    const MONTH_IN_MINUTES = 30 * DAY_IN_MINUTES;

    // Get current ranges for DAU calculation
    const dauRanges = [{
        name: 'daily_active_users',
        startMinutesAgo: DAY_IN_MINUTES,
        endMinutesAgo: 0
    }];

    // Get ranges for MAU calculation
    const mauRanges = [{
        name: 'monthly_active_users',
        startMinutesAgo: MONTH_IN_MINUTES,
        endMinutesAgo: 0
    }];

    // Calculate DAU/MAU ratio
    const calculateRatio = (dau, mau) => {
        if (mau === 0) return 0;
        return (dau / mau * 100).toFixed(2);
    };

    return {
        dauRanges,
        mauRanges,
        calculateRatio
    };
};

// export const combineMetrics = (dauData, mauData) => {
//     return {
//         dau: dauData?.activeUsers || 0,
//         mau: mauData?.activeUsers || 0,
//         ratio: calculateRatio(
//             dauData?.activeUsers || 0,
//             mauData?.activeUsers || 0
//         )
//     };
// };


export const HISTORICAL_RANGES = [
    '24 hours',
    '7 days',
    '25 days',
    '90 days',
    '180 days',
    '1 year',
    '3 years',
    '6 years'
];

export const DAU_MAU_RANGES = [
    'dauPerWau',
    'dauPerMau',
];
