import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AnalyticsNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "/dau-mau-analytics",
      label: "DAU/MAU",
    },
    {
      path: "/realtime-analytics",
      label: "Realtime",
    },
    {
      path: "/historical-analytics",
      label: "Historical",
    },
  ];

  return (
    <div className="flex  sm:flex-row gap-4 p-4">
      {navItems.map((item) => {
        const isActive = location.pathname === item.path;
        return (
          <button
            key={item.path}
            onClick={() => navigate(item.path)}
            className={`
                w-20 h-15 rounded-md
                flex items-center justify-center text-sm font-medium
                transition-all duration-300 transform
                focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-50
                shadow-lg
                ${
                  isActive
                    ? "bg-indigo-600 text-white scale-110 shadow-xl"
                    : "bg-indigo-100 text-indigo-600 hover:bg-indigo-50 hover:scale-105"
                }
              `}
          >
            <span
              className={`
                transition-all duration-300
                ${isActive ? "transform scale-105" : ""}
              `}
            >
              {item.label}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export { AnalyticsNavigation };
