// import React from 'react';

// // Custom Motion Component
// const Motion = ({
//     children,
//     initial = { opacity: 0, y: 20 },
//     animate = { opacity: 1, y: 0 },
//     transition = { duration: 0.3 },
//     className = '',
//     ...props
// }) => {
//     const [style, setStyle] = React.useState({
//         opacity: initial.opacity,
//         transform: `translateY(${initial.y}px)`
//     });

//     React.useEffect(() => {
//         // Trigger animation
//         const timer = setTimeout(() => {
//             setStyle({
//                 opacity: animate.opacity,
//                 transform: `translateY(${animate.y}px)`,
//                 transition: `all ${transition.duration}s ease-out`
//             });
//         }, 50);

//         return () => clearTimeout(timer);
//     }, [animate, transition]);

//     return (
//         <div
//             style={style}
//             className={`transition-all duration-300 ease-out ${className}`}
//             {...props}
//         >
//             {children}
//         </div>
//     );
// };

// // AnimatePresence Replacement
// const AnimatePresence = ({ children, mode = 'sync', ...props }) => {
//     const [isVisible, setIsVisible] = React.useState(true);

//     React.useEffect(() => {
//         if (mode === 'wait') {
//             const timer = setTimeout(() => {
//                 setIsVisible(false);
//             }, 300); // Match transition duration

//             return () => clearTimeout(timer);
//         }
//     }, [mode]);

//     return isVisible ? children : null;
// };

// export { Motion as motion, AnimatePresence };

import React from 'react';

// Motion Component
const Motion = React.forwardRef(({
    children,
    initial = { opacity: 0, y: 20 },
    animate = { opacity: 1, y: 0 },
    transition = { duration: 0.3 },
    className = '',
    ...props
}, ref) => {
    const [style, setStyle] = React.useState({
        opacity: initial.opacity,
        transform: `translateY(${initial.y}px)`
    });

    React.useEffect(() => {
        // Trigger animation
        const timer = setTimeout(() => {
            setStyle({
                opacity: animate.opacity,
                transform: `translateY(${animate.y}px)`,
                transition: `all ${transition.duration}s ease-out`
            });
        }, 50);

        return () => clearTimeout(timer);
    }, [animate, transition]);

    return (
        <div
            ref={ref}
            style={style}
            className={`transition-all duration-300 ease-out ${className}`}
            {...props}
        >
            {children}
        </div>
    );
});
Motion.displayName = 'Motion';

// AnimatePresence Component
const AnimatePresence = ({ children, mode = 'sync', ...props }) => {
    const [isVisible, setIsVisible] = React.useState(true);

    React.useEffect(() => {
        if (mode === 'wait') {
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 300); // Match transition duration

            return () => clearTimeout(timer);
        }
    }, [mode]);

    return isVisible ? children : null;
};
AnimatePresence.displayName = 'AnimatePresence';

// Div variant
const div = React.forwardRef((props, ref) => (
    <div ref={ref} {...props} />
));
div.displayName = 'MotionDiv';

// Export as a named export object
export const motion = {
    div,
    default: Motion
};

export { AnimatePresence };