// import React from 'react';
// import { RefreshCw, ArrowLeft, Users, Activity, TrendingUp, ChartBar } from 'lucide-react';
// import { useEngagementRatios } from '../hooks/dauMauHook';
// import { Card, CardContent } from '../ui/card';
// import { Button } from '../ui/button';
// import { useNavigate } from 'react-router-dom';
// import { AnalyticsNavigation } from './AnalyticsNavigation'

// const calculateAverageEngagement = (metric1, metric7, metric30) => {
//     // Get all valid values
//     const values = [
//         metric1?.dauPerWau,
//         metric7?.dauPerMau,
//         metric30?.dauPerMau
//     ]

//     // If no valid values, return '0'
//     if (values.length === 0) return '0';

//     // Calculate average of all valid values
//     const sum = values.reduce((acc, val) => acc + val, 0);
//     const average = sum / values.length;

//     return average.toFixed(2);
// };

// const MetricCard = ({ title, value, icon: Icon, loading, subtitle, trend }) => (
//     <Card className="group bg-white/80 backdrop-blur-sm hover:bg-white transition-all duration-500 hover:shadow-2xl hover:-translate-y-1 p-6">
//         <CardContent className="p-6 relative overflow-hidden">
//             <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-indigo-500/10 to-blue-500/5 rounded-bl-full transform translate-x-8 -translate-y-8 group-hover:translate-x-4 group-hover:-translate-y-4 transition-transform duration-500" />

//             <div className="flex items-center justify-between">
//                 <div className="space-y-3">
//                     <div className="flex items-center gap-2">
//                         <p className="text-sm font-medium text-slate-600">{title}</p>
//                         {trend && (
//                             <span className={`text-xs px-2 py-0.5 rounded-full ${trend > 0 ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
//                                 }`}>
//                                 {trend > 0 ? '+' : ''}{trend}%
//                             </span>
//                         )}
//                     </div>
//                     <div className="text-3xl font-bold bg-gradient-to-r from-indigo-900 to-blue-900 bg-clip-text text-transparent">
//                         {loading ? (
//                             <div className="h-9 w-24 bg-slate-200 animate-pulse rounded" />
//                         ) : (
//                             value
//                         )}
//                     </div>
//                     {subtitle && (
//                         <p className="text-sm text-slate-500 font-medium">{subtitle}</p>
//                     )}
//                 </div>
//                 <div className="bg-gradient-to-br from-indigo-500 to-blue-600 p-3 rounded-xl shadow-lg group-hover:shadow-indigo-500/25 transition-all duration-300">
//                     <Icon className="w-6 h-6 text-white" />
//                 </div>
//             </div>
//         </CardContent>
//     </Card>
// );

// const PageHeader = ({ onRefresh, loading, onBack, title, subtitle }) => (
//     <Card className="bg-gradient-to-r from-indigo-900 to-blue-900 border-none shadow-2xl transform hover:scale-[1.01] transition-all duration-300 p-5">
//         <CardContent className="flex flex-col md:flex-row justify-between items-center p-4">
//             <div className="space-y-2 text-center md:text-left mb-4 md:mb-0">
//                 <div className="flex items-center gap-4">
//                     <Button
//                         onClick={onBack}
//                         variant="ghost"
//                         className="p-2 text-white/80 hover:text-white hover:bg-white/10 transition-colors"
//                     >
//                         <ArrowLeft className="h-5 w-5" />
//                     </Button>
//                     <div className="flex items-center gap-3">
//                         <ChartBar className="h-8 w-8 text-blue-400" />
//                         <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-200 via-indigo-200 to-blue-300 animate-gradient">
//                             {title}
//                         </h1>
//                     </div>
//                 </div>
//                 <div className="flex items-center gap-2">
//                     <TrendingUp className="h-4 w-4 text-blue-400" />
//                     <p className="text-blue-200/80">{subtitle}</p>
//                 </div>
//                 <div className="max-w-7xl mx-auto mt-6  rounded-lg  p-6">
//                     <AnalyticsNavigation />
//                 </div>
//             </div>
//             <Button
//                 onClick={onRefresh}
//                 className="bg-blue-500 hover:bg-blue-600 text-white flex items-center gap-2 shadow-lg hover:shadow-blue-500/30 transition-all duration-300"
//                 disabled={loading}
//             >
//                 <RefreshCw className={`h-4 w-4 ${loading ? 'animate-spin' : ''}`} />
//                 Refresh Data
//             </Button>
//         </CardContent>
//     </Card>
// );

// const MetricsGrid = ({ metric1, metric7, metric30, loading }) => (
//     <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
//         <div className="opacity-0 fade-in" style={{ animationDelay: '0.2s' }}>
//             <MetricCard
//                 title="Daily Active Users"
//                 value={`${metric1?.dauPerWau ?? '0'}`}
//                 icon={Users}
//                 loading={loading}
//                 trend={2.5}
//             />
//         </div>
//         <div className="opacity-0 fade-in" style={{ animationDelay: '0.3s' }}>
//             <MetricCard
//                 title="weekly Active Users"
//                 value={`${metric7?.dauPerMau ?? '0'}`}
//                 icon={Activity}
//                 loading={loading}
//                 trend={-1.2}
//             />
//         </div>
//         <div className="opacity-0 fade-in" style={{ animationDelay: '0.3s' }}>
//             <MetricCard
//                 title="Monthly Active Users"
//                 value={`${metric30?.dauPerMau ?? '0'}`}
//                 icon={Activity}
//                 loading={loading}
//                 trend={-1.2}
//             />
//         </div>
//         <div className="opacity-0 fade-in" style={{ animationDelay: '0.4s' }}>
//             <MetricCard
//                 title="Engagement Sessions"
//                 value={`${metric7?.engagedSessions ?? '0'}`}
//                 icon={TrendingUp}
//                 loading={loading}
//                 trend={0.3}
//             />
//         </div>

//         <div className="opacity-0 fade-in" style={{ animationDelay: '0.4s' }}>
//             <MetricCard
//                 title="Overall Engagement"
//                 value={`${metric30?.engagementRate ?? '0'}`}
//                 icon={TrendingUp}
//                 loading={loading}
//                 trend={0.8}
//             />
//         </div>
//     </div>
// );

// const DAUMAUAnalyticsPage = () => {

//     const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID
//     const navigate = useNavigate();
//     const {
//         data: metricsData,
//         loading: metricsLoading,
//         error: metricsError,
//         refresh: refreshMetrics
//     } = useEngagementRatios(PROPERTY_ID);

//     // console.log(metricsData)

//     const metric1 = metricsData?.[1] ?? null;
//     const metric7 = metricsData?.[7] ?? null;
//     const metric30 = metricsData?.[30] ?? null;

//     if (metricsError) {
//         return (
//             <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-slate-50 to-slate-100">
//                 <Card className="bg-white/80 backdrop-blur-sm shadow-2xl border-red-100">
//                     <CardContent className="p-8 text-center">
//                         <div className="text-red-500 mb-4">
//                             <Activity className="h-12 w-12 mx-auto mb-4 opacity-80" />
//                             <p className="text-lg font-medium">Error loading metrics</p>
//                             <p className="text-sm text-red-400 mt-2">{metricsError}</p>
//                         </div>
//                         <Button
//                             onClick={refreshMetrics}
//                             className="bg-red-500 hover:bg-red-600 text-white transition-colors"
//                         >
//                             Retry
//                         </Button>
//                     </CardContent>
//                 </Card>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-gradient-to-b from-slate-50 via-indigo-50 to-slate-100 p-4 md:p-8 animate-gradient-slow">
//             <style jsx global>{`
//                 @keyframes gradient {
//                     0% { background-position: 0% 50%; }
//                     50% { background-position: 100% 50%; }
//                     100% { background-position: 0% 50%; }
//                 }
//                 .animate-gradient {
//                     background-size: 200% 200%;
//                     animation: gradient 6s ease infinite;
//                 }
//                 .animate-gradient-slow {
//                     background-size: 200% 200%;
//                     animation: gradient 15s ease infinite;
//                 }
//                 @keyframes fadeIn {
//                     from { opacity: 0; transform: translateY(10px); }
//                     to { opacity: 1; transform: translateY(0); }
//                 }
//                 .fade-in {
//                     animation: fadeIn 0.5s ease-out forwards;
//                 }
//             `}</style>
//             <div className="max-w-7xl mx-auto space-y-8">
//                 <div className="opacity-0 fade-in" style={{ animationDelay: '0.1s' }}>
//                     <PageHeader
//                         onRefresh={refreshMetrics}
//                         loading={metricsLoading}
//                         onBack={() => navigate('/')}
//                         title="User Activity Analysis"
//                         subtitle="Active Users and Engagement Metrics"
//                     />
//                 </div>
//                 <MetricsGrid
//                     metric1={metric1}
//                     metric7={metric7}
//                     metric30={metric30}
//                     loading={metricsLoading}
//                 />
//                 {/* Footer */}
//                 <div className="opacity-0 fade-in" style={{ animationDelay: '0.6s' }}>
//                     <Card className="bg-gradient-to-r from-slate-900 to-blue-900 border-none shadow-lg p-4">
//                         <CardContent className="flex ">
//                             {/* Logo Container */}
//                             <div className="absolute top-4 right-6">
//                                 <div className="relative group">
//                                     <div className="absolute -inset-2 bg-white/20 rounded-full blur transition duration-700 group-hover:bg-indigo-400/40 group-hover:duration-200"></div>
//                                     <div className="relative">
//                                         <img
//                                             src="/logo.png"
//                                             alt="ABBA Logo"
//                                             className="h-24 w-24 object-contain transform transition-all duration-500 group-hover:scale-110 "
//                                             onError={(e) => {
//                                                 e.target.onerror = null;
//                                                 e.target.src = `data:image/svg+xml,${encodeURIComponent(`
//                           <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <rect width="64" height="64" rx="32" fill="white"/>
//                             <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="20" font-weight="bold" fill="#4F46E5">
//                               ABBA
//                             </text>
//                           </svg>
//                         `)}`;
//                                             }}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                             <p className="text-blue-200/80 items-end justify-end p-6">
//                                 Copyright © {new Date().getFullYear()} Abba Info Services, Angamaly, Kerala, India
//                             </p>
//                         </CardContent>
//                     </Card>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default DAUMAUAnalyticsPage;

import React, { useMemo } from "react";
import {
  RefreshCw,
  ArrowLeft,
  Users,
  Activity,
  TrendingUp,
  ChartBar,
} from "lucide-react";
import { useEngagementRatios } from "../hooks/dauMauHook";
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import { AnalyticsNavigation } from "./AnalyticsNavigation";

// Utility function for calculating average engagement
const calculateAverageEngagement = (metrics) => {
  const validMetrics = [
    metrics?.[1]?.dauPerWau,
    metrics?.[7]?.dauPerMau,
    metrics?.[30]?.dauPerMau,
  ].filter((metric) => metric !== undefined);

  return validMetrics.length > 0
    ? (
        validMetrics.reduce((sum, val) => sum + val, 0) / validMetrics.length
      ).toFixed(2)
    : "0";
};

// Reusable metric card component with improved design
const MetricCard = ({
  title,
  value,
  icon: Icon,
  loading,
  trend,
  className,
}) => (
  <Card
    className={`
    group bg-white/80 backdrop-blur-sm 
    hover:bg-white transition-all duration-500 
    hover:shadow-2xl hover:-translate-y-1 
    ${className}
  `}
  >
    <CardContent className="p-6 relative overflow-hidden">
      {/* Gradient background effect */}
      <div
        className="
        absolute top-0 right-0 
        w-32 h-32 
        bg-gradient-to-br from-indigo-500/10 to-blue-500/5 
        rounded-bl-full 
        transform translate-x-8 -translate-y-8 
        group-hover:translate-x-4 group-hover:-translate-y-4 
        transition-transform duration-500 
      "
      />

      <div className="flex items-center justify-between">
        <div className="space-y-3 flex-grow">
          <div className="flex items-center gap-2">
            <p className="text-sm font-medium text-slate-600">{title}</p>
            {trend && (
              <span
                className={`
                  text-xs px-2 py-0.5 rounded-full 
                  ${
                    trend > 0
                      ? "bg-green-100 text-green-700"
                      : "bg-red-100 text-red-700"
                  }
                `}
              >
                {trend > 0 ? "+" : ""}
                {trend}%
              </span>
            )}
          </div>

          {loading ? (
            <div className="h-9 w-24 bg-slate-200 animate-pulse rounded" />
          ) : (
            <div
              className="
              text-3xl font-bold 
              bg-gradient-to-r from-indigo-900 to-blue-900 
              bg-clip-text text-transparent
            "
            >
              {value}
            </div>
          )}
        </div>

        {/* Icon container */}
        <div
          className="
          bg-gradient-to-br from-indigo-500 to-blue-600 
          p-3 rounded-xl shadow-lg 
          group-hover:shadow-indigo-500/25 
          transition-all duration-300
        "
        >
          <Icon className="w-6 h-6 text-white" />
        </div>
      </div>
    </CardContent>
  </Card>
);

// Page header component
const PageHeader = ({ onRefresh, loading, onBack, title, subtitle }) => (
  <Card
    className="
    bg-gradient-to-r from-yellow-900 to-blue-900 
    border-none shadow-2xl 
    transform hover:scale-[1.01] 
    transition-all duration-300 
    p-5
  "
  >
    <CardContent
      className="
      flex flex-col md:flex-row 
      justify-between items-center 
      p-4
    "
    >
      <div className="space-y-2 text-center md:text-left mb-4 md:mb-0">
        <div className="flex items-center gap-4">
          <Button
            onClick={onBack}
            variant="ghost"
            className="
              p-2 text-white/80 
              hover:text-white hover:bg-white/10 
              transition-colors
            "
          >
            <ArrowLeft className="h-5 w-5" />
          </Button>

          <div className="flex items-center gap-3">
            <ChartBar className="h-8 w-8 text-blue-400" />
            <h1
              className="
              text-4xl font-bold 
              text-transparent bg-clip-text 
              bg-gradient-to-r from-blue-200 via-indigo-200 to-blue-300 
              animate-gradient
            "
            >
              {title}
            </h1>
          </div>
        </div>

        <div className="flex items-center gap-2 mt-2">
          <TrendingUp className="h-4 w-4 text-blue-400" />
          <p className="text-blue-200/80">{subtitle}</p>
        </div>

        <div className="max-w-7xl mx-auto mt-6 rounded-lg p-6">
          <AnalyticsNavigation />
        </div>
      </div>

      <Button
        onClick={onRefresh}
        className="
          bg-blue-500 hover:bg-blue-600 
          text-white flex items-center gap-2 
          shadow-lg hover:shadow-blue-500/30 
          transition-all duration-300
        "
        disabled={loading}
      >
        <RefreshCw className={`h-4 w-4 ${loading ? "animate-spin" : ""}`} />
        Refresh Data
      </Button>
    </CardContent>
  </Card>
);

// Metrics grid component
const MetricsGrid = ({ metric1, metric7, metric30, loading }) => {
  const averageEngagement = useMemo(
    () => calculateAverageEngagement({ 1: metric1, 7: metric7, 30: metric30 }),
    [metric1, metric7, metric30]
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {[
        {
          title: "Daily Active Users",
          value: `${metric1?.dauPerWau ?? "0"}`,
          icon: Users,
          trend: 2.5,
          delay: "0.2s",
        },
        {
          title: "Weekly Active Users",
          value: `${metric7?.dauPerMau ?? "0"}`,
          icon: Activity,
          trend: -1.2,
          delay: "0.3s",
        },
        {
          title: "Monthly Active Users",
          value: `${metric30?.dauPerMau ?? "0"}`,
          icon: Activity,
          trend: -1.2,
          delay: "0.3s",
        },
        {
          title: "Engagement Sessions",
          value: `${metric7?.engagedSessions ?? "0"}`,
          icon: TrendingUp,
          trend: 0.3,
          delay: "0.4s",
        },
        {
          title: "Overall Engagement",
          value: `${metric30?.engagementRate ?? "0"}`,
          icon: TrendingUp,
          trend: 0.8,
          delay: "0.4s",
        },
        {
          title: "Average Engagement",
          value: averageEngagement,
          icon: ChartBar,
          trend: 0.6,
          delay: "0.5s",
        },
      ].map(({ title, value, icon, trend, delay }) => (
        <div
          key={title}
          className="opacity-0 fade-in"
          style={{ animationDelay: delay }}
        >
          <MetricCard
            title={title}
            value={value}
            icon={icon}
            loading={loading}
            trend={trend}
          />
        </div>
      ))}
    </div>
  );
};

// Main analytics page component
const DAUMAUAnalyticsPage = () => {
  const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID;
  const navigate = useNavigate();

  const {
    data: metricsData,
    loading: metricsLoading,
    error: metricsError,
    refresh: refreshMetrics,
  } = useEngagementRatios(PROPERTY_ID);

  const metric1 = metricsData?.[1] ?? null;
  const metric7 = metricsData?.[7] ?? null;
  const metric30 = metricsData?.[30] ?? null;

  // Error handling component
  if (metricsError) {
    return (
      <div
        className="
        min-h-screen 
        flex items-center justify-center 
        bg-gradient-to-b from-slate-50 to-slate-100
      "
      >
        <Card className="bg-white/80 backdrop-blur-sm shadow-2xl border-red-100">
          <CardContent className="p-8 text-center">
            <div className="text-red-500 mb-4">
              <Activity className="h-12 w-12 mx-auto mb-4 opacity-80" />
              <p className="text-lg font-medium">Error loading metrics</p>
              <p className="text-sm text-red-400 mt-2">{metricsError}</p>
            </div>
            <Button
              onClick={refreshMetrics}
              className="bg-red-500 hover:bg-red-600 text-white transition-colors"
            >
              Retry
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div
      className="
      min-h-screen 
      bg-gradient-to-b from-slate-50 via-indigo-50 to-slate-100 
      p-4 md:p-8 
      animate-gradient-slow
    "
    >
      {/* Global styles for animations */}
      <style jsx global>{`
        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        .animate-gradient {
          background-size: 200% 200%;
          animation: gradient 6s ease infinite;
        }
        .animate-gradient-slow {
          background-size: 200% 200%;
          animation: gradient 15s ease infinite;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .fade-in {
          animation: fadeIn 0.5s ease-out forwards;
        }
      `}</style>

      <div className="max-w-7xl mx-auto space-y-8">
        {/* Fade-in page header */}
        <div className="opacity-0 fade-in" style={{ animationDelay: "0.1s" }}>
          <PageHeader
            onRefresh={refreshMetrics}
            loading={metricsLoading}
            onBack={() => navigate("/")}
            title="User Activity Analysis"
            subtitle="Active Users and Engagement Metrics"
          />
        </div>

        {/* Metrics grid */}
        <MetricsGrid
          metric1={metric1}
          metric7={metric7}
          metric30={metric30}
          loading={metricsLoading}
        />

        {/* Footer */}
        <div className="opacity-0 fade-in" style={{ animationDelay: "0.6s" }}>
          <Card className=" bg-gradient-to-r from-yellow-900 to-blue-900   border-none shadow-lg      p-6          ">
            <CardContent className="flex relative">
              {/* Logo Container */}
              <div className="absolute top-2 right-6">
                <div className="relative group">
                  <div
                    className="
                    absolute -inset-2 
                    bg-white/20 rounded-full 
                    blur transition duration-700 
                    group-hover:bg-indigo-400/40 
                    group-hover:duration-200
                  "
                  ></div>
                  <div className="relative">
                    <img
                      src="/logo.png"
                      alt="ABBA Logo"
                      className="
                        h-24 w-24 object-contain 
                        transform transition-all duration-500 
                        group-hover:scale-110
                      "
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `data:image/svg+xml,${encodeURIComponent(`
                          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" rx="32" fill="white"/>
                            <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="20" font-weight="bold" fill="#4F46E5">
                              ABBA
                            </text>
                          </svg>
                        `)}`;
                      }}
                    />
                  </div>
                </div>
              </div>

              <p
                className="
                text-blue-200/80 
                items-end justify-end 
                p-6
              "
              >
                Copyright © {new Date().getFullYear()} Abba Info Services,
                Angamaly, Kerala, India
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DAUMAUAnalyticsPage;
