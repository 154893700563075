// import React, { useEffect } from 'react';
// import { RefreshCw, ArrowLeft, Activity, Clock } from 'lucide-react';
// import { useRealtimeAnalytics } from '../hooks/realtimeDataHook';
// import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
// import { Button } from '../ui/button'
// import { useNavigate } from 'react-router-dom';
// import QuickStatsRealtime from '../ui/QuickStatsRealtime';
// import DataTable from '../ui/DataTable';
// import { AnalyticsNavigation } from './AnalyticsNavigation';

// const REFRESH_INTERVAL = 1200000; //20 minutes

// const LiveIndicator = () => (
//     <div className="flex items-center gap-2 bg-green-500/10 px-3 py-1 rounded-full">
//         <div className="h-2 w-2 rounded-full bg-green-500 animate-pulse" />
//         <span className="text-green-500 text-sm font-medium">LIVE</span>
//     </div>
// );

// const PageHeader = ({ onBack, onRefresh, isLoading }) => (
//     <Card className="bg-gradient-to-r from-emerald-900 to-blue-900 border-none shadow-2xl transform hover:scale-[1.01] transition-all duration-300 p-6">
//         <CardContent className="flex flex-col md:flex-row justify-between items-center p-8">
//             <div className="space-y-3 text-center md:text-left mb-4 md:mb-0">
//                 <div className="flex items-center gap-4">
//                     <Button
//                         onClick={onBack}
//                         variant="ghost"
//                         className="p-2 text-white/80 hover:text-white hover:bg-white/10 transition-colors"
//                     >
//                         <ArrowLeft className="h-5 w-5" />
//                     </Button>
//                     <div className="flex items-center gap-3">
//                         <Activity className="h-8 w-8 text-emerald-400 animate-pulse" />
//                         <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-emerald-200 via-blue-100 to-emerald-300 animate-gradient">
//                             Realtime Analytics
//                         </h1>
//                     </div>
//                     <LiveIndicator />
//                 </div>
//                 <div className="flex items-center gap-2 text-emerald-200/80">
//                     <Clock className="h-4 w-4" />
//                     <p>Live analytics data from the last 24 hours</p>
//                 </div>
//                 <div className="max-w-7xl mx-auto">
//                     <AnalyticsNavigation />
//                 </div>
//             </div>
//             <Button
//                 onClick={onRefresh}
//                 className="bg-emerald-500 hover:bg-emerald-600 text-white flex items-center gap-2 shadow-lg hover:shadow-emerald-500/30 transition-all duration-300"
//                 disabled={isLoading}
//             >
//                 <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
//                 Refresh Data
//             </Button>
//         </CardContent>
//     </Card>
// );

// const DataSection = ({ data, isLoading, error, onRetry }) => (
//     <Card className="backdrop-blur-sm bg-white/90 shadow-xl hover:shadow-2xl transition-all duration-300 border border-slate-200">
//         <CardHeader className="border-b border-slate-100">
//             <div className="flex items-center justify-between">
//                 <CardTitle className="text-xl font-semibold bg-gradient-to-r from-emerald-900 to-blue-900 bg-clip-text text-transparent">
//                     Detailed Analytics
//                 </CardTitle>
//                 <div className="flex items-center gap-2 text-sm text-slate-500">
//                     <Clock className="h-4 w-4" />
//                     <span>Auto-refreshes every 20 minute</span>
//                 </div>
//             </div>
//         </CardHeader>
//         <CardContent className="p-6">
//             <DataTable
//                 data={data}
//                 loading={isLoading}
//                 error={error}
//                 onRetry={onRetry}
//                 variant="realtime"
//             />
//         </CardContent>
//     </Card>
// );

// const RealtimeAnalyticsPage = () => {
//     const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID
//     const navigate = useNavigate();
//     const {
//         data: realtimeData,
//         loading: realtimeLoading,
//         error: realtimeError,
//         refresh: refreshRealtime
//     } = useRealtimeAnalytics(PROPERTY_ID, REFRESH_INTERVAL);

//     useEffect(() => {
//         const intervalId = setInterval(refreshRealtime, REFRESH_INTERVAL);
//         return () => clearInterval(intervalId);
//     }, [refreshRealtime]);

//     return (
//         <div className="min-h-screen bg-gradient-to-b from-slate-50 via-emerald-50 to-slate-100 p-4 md:p-8 animate-gradient-slow">
//             <style jsx global>{`
//         @keyframes gradient {
//           0% { background-position: 0% 50%; }
//           50% { background-position: 100% 50%; }
//           100% { background-position: 0% 50%; }
//         }
//         .animate-gradient {
//           background-size: 200% 200%;
//           animation: gradient 6s ease infinite;
//         }
//         .animate-gradient-slow {
//           background-size: 200% 200%;
//           animation: gradient 15s ease infinite;
//         }
//         @keyframes fadeIn {
//           from { opacity: 0; transform: translateY(10px); }
//           to { opacity: 1; transform: translateY(0); }
//         }
//         .fade-in {
//           animation: fadeIn 0.5s ease-out forwards;
//         }
//         @keyframes pulse {
//           0%, 100% { opacity: 1; }
//           50% { opacity: 0.5; }
//         }
//         .animate-pulse {
//           animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
//         }
//       `}</style>
//             <div className="max-w-7xl mx-auto space-y-8">
//                 <div className="opacity-0 fade-in" style={{ animationDelay: '0.1s' }}>
//                     <PageHeader
//                         onBack={() => navigate('/')}
//                         onRefresh={refreshRealtime}
//                         isLoading={realtimeLoading}
//                     />
//                 </div>

//                 <div className="opacity-0 fade-in" style={{ animationDelay: '0.3s' }}>
//                     <Card className="bg-gradient-to-br from-white via-emerald-50 to-white border border-emerald-100 p-7 shadow-xl hover:shadow-2xl transition-all duration-300">
//                         <CardContent className="p-6">
//                             <QuickStatsRealtime realtimeData={realtimeData} />
//                         </CardContent>
//                     </Card>
//                 </div>

//                 <div className="opacity-0 fade-in" style={{ animationDelay: '0.5s' }}>
//                     <DataSection
//                         data={realtimeData}
//                         isLoading={realtimeLoading}
//                         error={realtimeError}
//                         onRetry={refreshRealtime}
//                     />
//                 </div>
//                 {/* Footer */}
//                 <div className="opacity-0 fade-in" style={{ animationDelay: '0.6s' }}>
//                     <Card className="bg-gradient-to-r from-slate-900 to-blue-900 border-none shadow-lg p-4">
//                         <CardContent className="flex ">
//                             {/* Logo Container */}
//                             <div className="absolute top-4 right-6">
//                                 <div className="relative group">
//                                     <div className="absolute -inset-2 bg-white/20 rounded-full blur transition duration-700 group-hover:bg-indigo-400/40 group-hover:duration-200"></div>
//                                     <div className="relative">
//                                         <img
//                                             src="/logo.png"
//                                             alt="ABBA Logo"
//                                             className="h-24 w-24 object-contain transform transition-all duration-500 group-hover:scale-110 "
//                                             onError={(e) => {
//                                                 e.target.onerror = null;
//                                                 e.target.src = `data:image/svg+xml,${encodeURIComponent(`
//                           <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <rect width="64" height="64" rx="32" fill="white"/>
//                             <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="20" font-weight="bold" fill="#4F46E5">
//                               ABBA
//                             </text>
//                           </svg>
//                         `)}`;
//                                             }}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                             <p className="text-blue-200/80 items-end justify-end p-6">
//                                 Copyright © {new Date().getFullYear()} Abba Info Services, Angamaly, Kerala, India
//                             </p>
//                         </CardContent>
//                     </Card>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default RealtimeAnalyticsPage;

import React, { useEffect, useMemo } from "react";
import {
  RefreshCw,
  ArrowLeft,
  Activity,
  Clock,
  HeartPulseIcon,
} from "lucide-react";
import { useRealtimeAnalytics } from "../hooks/realtimeDataHook";
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import QuickStatsRealtime from "../ui/QuickStatsRealtime";
import DataTable from "../ui/DataTable";
import { AnalyticsNavigation } from "./AnalyticsNavigation";

// Constants
const REFRESH_INTERVAL = 1200000; // 20 minutes

// Live Indicator Component
const LiveIndicator = () => (
  <div
    className="
    flex items-center gap-2 
    bg-green-500/10 px-3 py-1 
    rounded-full
  "
  >
    <div
      className="
      h-2 w-2 rounded-full 
      bg-green-500 animate-pulse
    "
    />
    <span
      className="
      text-green-500 text-sm 
      font-medium
    "
    >
      LIVE
    </span>
  </div>
);

// Page Header Component
const PageHeader = ({ onBack, onRefresh, isLoading }) => (
  <Card
    className="
    bg-gradient-to-r from-emerald-900 to-blue-900 
    border-none shadow-2xl 
    transform hover:scale-[1.01] 
    transition-all duration-300 
    p-6
  "
  >
    <CardContent
      className="
      flex flex-col md:flex-row 
      justify-between items-center 
      p-8
    "
    >
      <div
        className="
        space-y-3 
        text-center md:text-left 
        mb-4 md:mb-0
      "
      >
        <div className="flex items-center gap-4">
          {/* Back Button */}
          <Button
            onClick={onBack}
            variant="ghost"
            className="
              p-2 text-white/80 
              hover:text-white hover:bg-white/10 
              transition-colors
            "
          >
            <ArrowLeft className="h-5 w-5" />
          </Button>

          {/* Title Section */}
          <div className="flex items-center gap-3">
            <Activity
              className="
              h-8 w-8 
              text-emerald-400 
              animate-pulse
            "
            />
            <h1
              className="
              text-4xl font-bold 
              text-transparent bg-clip-text 
              bg-gradient-to-r from-emerald-200 
              via-blue-100 to-emerald-300 
              animate-gradient
            "
            >
              Realtime Analytics
            </h1>

            <LiveIndicator />
          </div>
        </div>

        {/* Subtitle */}
        <div
          className="
          flex items-center gap-2 
          text-emerald-200/80 mt-2
        "
        >
          <Clock className="h-4 w-4" />
          <p>Live analytics data from the last 24 hours</p>
        </div>

        {/* Analytics Navigation */}
        <div className="max-w-7xl mx-auto mt-4">
          <AnalyticsNavigation />
        </div>
      </div>

      {/* Refresh Button */}
      <Button
        onClick={onRefresh}
        className="
          bg-emerald-500 hover:bg-emerald-600 
          text-white flex items-center gap-2 
          shadow-lg hover:shadow-emerald-500/30 
          transition-all duration-300
        "
        disabled={isLoading}
      >
        <RefreshCw
          className={`
            h-4 w-4 
            ${isLoading ? "animate-spin" : ""}
          `}
        />
        Refresh Data
      </Button>
    </CardContent>
  </Card>
);

// Data Section Component
const DataSection = ({ data, isLoading, error, onRetry }) => (
  <Card
    className="
    backdrop-blur-sm bg-white/90 
    shadow-xl hover:shadow-2xl 
    transition-all duration-300 
    border border-slate-200
  "
  >
    <CardHeader className="border-b border-slate-100">
      <div className="flex items-center justify-between">
        <CardTitle
          className="
          text-xl font-semibold 
          bg-gradient-to-r from-emerald-900 
          to-blue-900 bg-clip-text 
          text-transparent
        "
        >
          Detailed Analytics
        </CardTitle>

        <div
          className="
          flex items-center gap-2 
          text-sm text-slate-500
        "
        >
          <Clock className="h-4 w-4" />
          <span>Auto-refreshes every 20 minutes</span>
        </div>
      </div>
    </CardHeader>

    <CardContent className="p-6">
      <DataTable
        data={data}
        loading={isLoading}
        error={error}
        onRetry={onRetry}
        variant="realtime"
      />
    </CardContent>
  </Card>
);

// Footer Component
const PageFooter = () => (
  <Card
    className="bg-gradient-to-r from-emerald-600 to-blue-600 border-none shadow-lg p-6
  "
  >
    <CardContent className="flex relative">
      {/* Logo Container */}
      <div className="absolute top-2 right-6">
        <div className="relative group">
          <div
            className="
            absolute -inset-2 
            bg-white/20 rounded-full 
            blur transition duration-700 
            group-hover:bg-indigo-400/40 
            group-hover:duration-200
          "
          ></div>

          <div className="relative">
            <img
              src="/logo.png"
              alt="ABBA Logo"
              className="
                h-24 w-24 object-contain 
                transform transition-all 
                duration-500 group-hover:scale-110
              "
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `data:image/svg+xml,${encodeURIComponent(`
                  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="64" height="64" rx="32" fill="white"/>
                    <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="20" font-weight="bold" fill="#4F46E5">
                      ABBA
                    </text>
                  </svg>
                `)}`;
              }}
            />
          </div>
        </div>
      </div>

      <p
        className="
        text-blue-200/80 
        items-end justify-end 
        p-6
      "
      >
        Copyright © {new Date().getFullYear()} Abba Info Services, Angamaly,
        Kerala, India
      </p>
    </CardContent>
  </Card>
);

// Main Realtime Analytics Page Component
const RealtimeAnalyticsPage = () => {
  const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID;
  const navigate = useNavigate();

  const {
    data: realtimeData,
    loading: realtimeLoading,
    error: realtimeError,
    refresh: refreshRealtime,
  } = useRealtimeAnalytics(PROPERTY_ID, REFRESH_INTERVAL);

  // Auto-refresh effect
  useEffect(() => {
    const intervalId = setInterval(refreshRealtime, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [refreshRealtime]);

  // Memoize error handling
  const errorComponent = useMemo(() => {
    if (realtimeError) {
      return (
        <div
          className="
          min-h-screen flex 
          items-center justify-center 
          bg-gradient-to-b from-slate-50 to-slate-100
        "
        >
          <Card
            className="
            bg-white/80 backdrop-blur-sm 
            shadow-2xl border-red-100
          "
          >
            <CardContent className="p-8 text-center">
              <div className="text-red-500 mb-4">
                <HeartPulseIcon
                  className="
                  h-12 w-12 mx-auto 
                  mb-4 opacity-80
                "
                />
                <p
                  className="
                  text-lg font-medium
                "
                >
                  Error loading realtime analytics
                </p>
                <p
                  className="
                  text-sm text-red-400 mt-2
                "
                >
                  {realtimeError}
                </p>
              </div>
              <Button
                onClick={refreshRealtime}
                className="
                  bg-red-500 hover:bg-red-600 
                  text-white transition-colors
                "
              >
                Retry
              </Button>
            </CardContent>
          </Card>
        </div>
      );
    }
    return null;
  }, [realtimeError, refreshRealtime]);

  // If there's an error, render the error component
  if (errorComponent) return errorComponent;

  return (
    <div
      className="
      min-h-screen 
      bg-gradient-to-b from-slate-50 
      via-emerald-50 to-slate-100 
      p-4 md:p-8 
      animate-gradient-slow
    "
    >
      {/* Global Animations */}
      <style jsx global>{`
        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        .animate-gradient {
          background-size: 200% 200%;
          animation: gradient 6s ease infinite;
        }
        .animate-gradient-slow {
          background-size: 200% 200%;
          animation: gradient 15s ease infinite;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .fade-in {
          animation: fadeIn 0.5s ease-out forwards;
        }
        @keyframes pulse {
          0%,
          100% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
        }
        .animate-pulse {
          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
      `}</style>

      <div className="max-w-7xl mx-auto space-y-8">
        {/* Fade-in Page Header */}
        <div className="opacity-0 fade-in" style={{ animationDelay: "0.1s" }}>
          <PageHeader
            onBack={() => navigate("/")}
            onRefresh={refreshRealtime}
            isLoading={realtimeLoading}
          />
        </div>

        {/* Quick Stats Section */}
        <div className="opacity-0 fade-in" style={{ animationDelay: "0.3s" }}>
          <Card
            className="
            bg-gradient-to-br from-white 
            via-emerald-50 to-white 
            border border-emerald-100 
            p-7 shadow-xl 
            hover:shadow-2xl 
            transition-all duration-300
          "
          >
            <CardContent className="p-6">
              <QuickStatsRealtime realtimeData={realtimeData} />
            </CardContent>
          </Card>
        </div>

        {/* Detailed Analytics Section */}
        <div className="opacity-0 fade-in" style={{ animationDelay: "0.5s" }}>
          <DataSection
            data={realtimeData}
            isLoading={realtimeLoading}
            error={realtimeError}
            onRetry={refreshRealtime}
          />
        </div>

        {/* Footer */}
        <div className="opacity-0 fade-in" style={{ animationDelay: "0.6s" }}>
          <PageFooter />
        </div>
      </div>
    </div>
  );
};

export default RealtimeAnalyticsPage;
