
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/Auth/AuthContext';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import HomePage from './components/Pages/HomePage';
import RealtimeAnalyticsPage from './components/Pages/realtimeAnalyticsPage';
import HistoricalAnalyticsPage from './components/Pages/historicalAnalyticsPage';
import DAUMAUAnalyticsPage from './components/Pages/dauMauAnalyticsPage';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ProtectedRoute>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/realtime-analytics" element={<RealtimeAnalyticsPage />} />
            <Route path="/historical-analytics" element={<HistoricalAnalyticsPage />} />
            <Route path="/dau-mau-analytics" element={<DAUMAUAnalyticsPage />} />
          </Routes>
        </ProtectedRoute>
      </Router>
    </AuthProvider>
  );
};

export default App;